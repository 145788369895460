<template>
  <div>
    <component
      :is="tag"
      :class="$props.cssClasses"
    >
      <span class="d-flex align-center">
        {{ html }}
        <v-tooltip right>
          <template #activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="primary"
              v-if="helpText"
              small
              icon
              class="ml-2"
              tabindex="-1"
              @click="dialog = true"
            >
              <v-icon>
                {{ $icons.mdiHelpCircle }}
              </v-icon>
            </v-btn>
          </template>
          <span v-text="toggleButtonText"></span>
        </v-tooltip>
      </span>
    </component>
    <v-dialog
      v-model="dialog"
      v-if="helpText"
      :max-width="640"
      scrollable
    >
      <v-card>
        <v-card-title
          class="text-h6 white--text primary"
          v-text="html"
        ></v-card-title>
        <v-card-text
          v-html="helpText"
          class="pt-6 v-card__text--markdown"
        ></v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
            v-text="closeButtonText"
          ></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Markdown from '@/mixins/markdown.mixin'

export default {
  name: 'HelpDialog',
  mixins: [
    Markdown
  ],
  props: {
    path: {
      type: String,
      required: true
    },
    tag: {
      type: String,
      default: 'div',
      validator: (tag) => {
        return ['div', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'span'].indexOf(tag) >= 0
      }
    },
    cssClasses: {
      type: String,
      default: ''
    }
  },
  inject: [
    '$tp',
    'pageTranslationKey'
  ],
  data () {
    return {
      dialog: false
    }
  },
  computed: {
    ...mapGetters('keycloak', ['userRole']),
    html () {
      if (this.$te(`${this.pageTranslationKey}.${this.path}`)) {
        return this.$tp(this.path)
      } else if (this.$te(this.path)) {
        return this.$t(this.path)
      }
      return ''
    },
    helpText () {
      let helpText = ''
      if (this.$te(`${this.pageTranslationKey}.${this.path}_help`)) {
        helpText = this.$tp(`${this.path}_help`)
      } else if (this.$te(`${this.path}_help`)) {
        helpText = this.$t(`${this.path}_help`)
      }
      if (typeof helpText === 'object') {
        if (helpText[this.userRole] !== undefined) {
          return this.parseMarkdown(helpText[this.userRole])
        } else if (helpText.default !== undefined) {
          return this.parseMarkdown(helpText.default)
        }
        return ''
      } else {
        return this.parseMarkdown(helpText)
      }
    },
    toggleButtonText () {
      return this.$t('global.components.helpDialog.toggleButton')
    },
    closeButtonText () {
      return this.$t('global.components.helpDialog.closeButton')
    }
  }
}
</script>

<style scoped>
/*.v-card__title {*/
/*  overflow-wrap: normal;*/
/*  word-break: normal;*/
/*}*/

/*.v-card__text {*/
/*  white-space: break-spaces;*/
/*}*/

.v-card__text--markdown >>> h1,
.v-card__text--markdown >>> h2,
.v-card__text--markdown >>> h3,
.v-card__text--markdown >>> h4,
.v-card__text--markdown >>> h5,
.v-card__text--markdown >>> h6 {
  margin-bottom: 0.8em
}

>>> .v-card__title {
  word-break: break-word;
}

</style>
