<template>
  <v-form
    ref="form"
    v-model="valid"
  >
    <v-container fluid class="ma-0 pa-0">
      <v-row v-for="(row, i) in normalizedConfig" :key="i">
        <v-col v-for="(col, j) in row" :key="`${i}-${j}`" cols="12" :md="row.length === 1 ? '12' : '6'" class="py-2 py-md-3">
          <template v-for="field in col">
            <template v-if="field.component === 'v-date-picker'">
              <date-type :key="field.name" v-model="rawFormData[field.name]" v-bind="getFieldProps(field)"></date-type>
            </template>

            <template v-else-if="field.component === 'v-radio-group'">
              <radio-group-type :key="field.name" v-model="rawFormData[field.name]" v-bind="getFieldProps(field)"></radio-group-type>
            </template>

            <template v-else-if="field.component === 'v-select'">
              <select-type :key="field.name" v-model="rawFormData[field.name]" v-bind="getFieldProps(field)"></select-type>
            </template>

            <template v-else-if="field.component === 'file-type'">
              <file-type :key="field.name" v-model="rawFormData[field.name]" v-bind="getFieldProps(field)" :name="field.name" class="mb-5">
                <template v-for="(_, name) in fileTypeScopedSlots" #[name]="data">
                  <slot :name="name" v-bind="data"></slot>
                </template>
              </file-type>
            </template>

            <template v-else-if="field.component === 'text-type'">
              <text-type :key="field.name" v-bind="getFieldProps(field)"></text-type>
            </template>

            <template v-else-if="field.component === 'v-autocomplete'">
              <v-autocomplete
                :key="field.name"
                v-model="rawFormData[field.name]"
                v-bind="getFieldProps(field)"
                @blur="validateField(field.name)"
              ></v-autocomplete>

              <slot :name="`autocomplete.${field.name}.after`" :value="rawFormData[field.name]"></slot>
            </template>

            <template v-else-if="field.component === 'syntax-highlighted-input'">
              <syntax-highlighted-input :key="field.name" v-model="rawFormData[field.name]" v-bind="getFieldProps(field)"></syntax-highlighted-input>
            </template>

            <component
              v-else
              :is="field.component"
              :key="field.name"
              v-model="rawFormData[field.name]"
              v-bind="getFieldProps(field)"
              @blur="handleBlur(field.name)"
            ></component>
          </template>
        </v-col>
      </v-row>
    </v-container>

    <slot
      v-if="!hideFooter"
      name="form.footer"
      :submit="submit"
      :submitText="submitText"
      :cancel="cancel"
      :cancelText="cancelText"
      :valid="valid"
    >
      <div class="d-flex flex-row justify-space-between mt-4 mx-n1">
        <v-btn
          text
          @click="cancel"
          color="red"
          class="white--text px-1"
        >
          {{ cancelText }}
        </v-btn>

        <v-btn
          text
          :disabled="!valid"
          color="green"
          class="white--text px-1"
          @click="submit"
        >
          {{ submitText }}
        </v-btn>
      </div>
    </slot>
  </v-form>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import flattenDeep from 'lodash/flattenDeep'
import find from 'lodash/find'
import isArray from 'lodash/isArray'
import isFunction from 'lodash/isFunction'
import upperFirst from 'lodash/upperFirst'

import { VAutocomplete, VTextField, VTextarea } from 'vuetify/lib'

import DateType from './DateType'
import FileType from './FileType'
import RadioGroupType from './RadioGroupType'
import SelectType from './SelectType'
import SyntaxHighlightedInput from './SyntaxHighlightedInput'
import TextType from './TextType'

import UserRoleAwareMixin from '@/mixins/userRoleAware.mixin'

export default {
  name: 'FormBuilder',
  mixins: [
    UserRoleAwareMixin
  ],
  components: {
    VAutocomplete,
    DateType,
    FileType,
    RadioGroupType,
    SelectType,
    SyntaxHighlightedInput,
    TextType,
    VTextField,
    VTextarea
  },
  props: {
    config: {
      type: Array,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    rules: {
      type: Object,
      default: () => ({})
    },
    transformers: {
      type: Object,
      default: () => ({})
    },
    translationBase: {
      type: String,
      default: 'components'
    },
    formName: {
      type: String,
      default: 'form'
    },
    hideFooter: {
      type: Boolean,
      default: false
    },
    roles: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      valid: false,
      rawFormData: Object.assign({}, this.data),
      formRules: Object.assign({}, this.rules),
      initializedFormRules: {},
      defaultProps: {
        outlined: true,
        required: true
      }
    }
  },
  created () {
    this.createWatchers()
    this.setUpRules()
  },
  methods: {
    getFieldProps (field, defaults = true) {
      const initialObject = {}

      if (defaults) {
        const fieldTranslationKey = `${this.baseFieldTranslationKey}.${field.name}`

        initialObject.label = this.$td(`${fieldTranslationKey}.label`, field.name)
        initialObject.placeholder = this.$td(`${fieldTranslationKey}.placeholder`, field.name)
      }

      const rules = this.initializedFormRules[field.name]

      return Object.assign(initialObject, defaults ? { ...this.defaultProps, readonly: this.readonly, disabled: this.disabled } : {}, {
        ...field.props,
        rules,
        ref: field.name
      })
    },
    setUpRules () {
      this.fields.forEach((field) => {
        const rules = (this.formRules[field] ?? []).map(fn => {
          let _fn = fn
          let options = {}

          if (isArray(fn)) {
            _fn = fn[0]
            options = fn[1]
          }

          if (isFunction(_fn)) {
            return _fn(options, { data: this.rawFormData, labels: this.labels })
          }

          return _fn
        })

        this.$set(this.initializedFormRules, field, [...rules])
      })
    },
    submit () {
      this.$emit('submit', this.formData)
    },
    cancel () {
      this.$emit('cancel')
    },
    createWatchers () {
      Object.keys(this.data).forEach((key) => {
        this.$watch(() => {
          return this.rawFormData[key]
        }, () => {
          this.$emit('reset', key)
        })
      })
    },
    reset () {
      this.$set(this, 'rawFormData', cloneDeep(this.data))
      this.$refs.form.resetValidation()
    },
    getFilteredScopedSlots (regex) {
      return Object.keys(this.$scopedSlots)
        .filter(name => name.search(regex) !== -1)
        .reduce((slots, name) => {
          slots[name] = this.$scopedSlots[name]
          return slots
        }, {})
    },
    validateField (field) {
      return this.$refs[field]?.validate()
    },
    validate () {
      this.$refs.form.validate()
    },
    handleBlur (fieldName) {
      let value = this.rawFormData[fieldName]
      if (typeof value === 'string') {
        value = value
          .replaceAll(/\s+/g, ' ')
          .trim()
        this.$set(this.rawFormData, fieldName, value)
      }
    }
  },
  computed: {
    isValid () {
      return this.valid
    },
    normalizedConfig () {
      return this.config.reduce((acc, curr) => acc && isArray(curr), true) ? this.config : [[this.config]]
    },
    fileTypeScopedSlots () {
      return this.getFilteredScopedSlots(/^fileType/)
    },
    baseTranslationKey () {
      return `pages.${this.page}.${this.translationBase}.${this.formName}`
    },
    baseFieldTranslationKey () {
      return `${this.baseTranslationKey}.fields`
    },
    submitText () {
      return this.$td(`${this.baseTranslationKey}.buttons.submit`, 'Submit')
    },
    cancelText () {
      return this.$td(`${this.baseTranslationKey}.buttons.cancel`, 'Cancel')
    },
    fields () {
      return flattenDeep(this.normalizedConfig).map(({ name }) => name)
    },
    labels () {
      return flattenDeep(this.normalizedConfig).reduce((labels, { name }) => {
        labels[name] = this.$td(`${this.baseFieldTranslationKey}.${name}.label`, name)

        return labels
      }, {})
    },
    formData () {
      return Object.keys(this.rawFormData).reduce((data, fieldName) => {
        if (find(flattenDeep(this.config), fieldConfig => fieldConfig.name === fieldName)) {
          data[fieldName] = this.transformers[fieldName]
            ? this.transformers[fieldName](this.rawFormData[fieldName])
            : this.rawFormData[fieldName]
        }

        return data
      }, {})
    },
    readonly () {
      if (this.roles.length > 0) {
        return !this.roles
          .reduce((acc, role) => {
            const methodName = `is${upperFirst(role)}`
            return acc || this[methodName]
          }, false)
      }
      return false
    },
    disabled () {
      if (this.roles.length > 0) {
        return !this.roles
          .reduce((acc, role) => {
            const methodName = `is${upperFirst(role)}`
            return acc || this[methodName]
          }, false)
      }
      return false
    }
  },
  inject: [
    '$td',
    'page'
  ],
  provide () {
    return {
      formName: this.formName,
      page: this.page,
      baseTranslationKey: this.baseTranslationKey,
      baseFieldTranslationKey: this.baseFieldTranslationKey
    }
  },
  watch: {
    data: {
      deep: true,
      initial: true,
      handler (newValue) {
        this.$set(this, 'rawFormData', Object.assign({}, newValue))

        this.$refs.form.resetValidation()
      }
    },
    rules: {
      deep: true,
      handler (newValue) {
        this.$set(this, 'formRules', newValue)
        this.setUpRules()
      }
    },
    errors: {
      handler (errors) {
        Object.keys(errors).forEach((name) => {
          const error = errors[name]

          if (!error) {
            this.$set(this.formRules, name, this.rules[name])
            return
          }

          const rules = Object.assign([], this.rules[name])
          rules.push(error)
          this.$set(this.formRules, name, rules)
        })

        const hasErrors = Object.values(errors)
          .reduce((acc, error) => acc || !!error, false)

        if (hasErrors) {
          this.validate()
        }
        this.setUpRules()
      },
      deep: true
    },
    rawFormData: {
      handler: debounce(function () {
        this.$emit('change')
        this.setUpRules()
      }, 250),
      deep: true
    }
  }
}
</script>
