<template>
  <v-dialog
    width="600"
    :value="value"
    @input="handleInput"
    scrollable
  >
    <v-card class="px-4 py-4">
      <v-card-title
        v-text="title"
      ></v-card-title>
      <v-card-text
        v-if="texts"
        class="pb-0"
      >
        <div
          v-for="(text, i) in texts"
          :key="i"
          v-html="text"
        ></div>
      </v-card-text>
      <v-card-actions
        :class="[xsColumn ? 'd-flex flex-column flex-sm-row align-end' : '']"
      >
        <v-btn
          text
          color="primary"
          @click="handleCancel"
        >
          {{ cancelButtonText }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          :class="[xsColumn ? 'mt-2 mt-sm-0' : '']"
          text
          :color="confirmColor"
          :disabled="loading"
          :loading="loading"
          @click="handleConfirm"
        >
          {{ confirmButtonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import isArray from 'lodash/isArray'
import isString from 'lodash/isString'
import { marked } from 'marked'

export default {
  name: 'ConfirmationDialog',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    text: {
      type: [Array, String],
      default: null
    },
    cancel: {
      type: String,
      default: ''
    },
    confirm: {
      type: String,
      default: ''
    },
    confirmColor: {
      type: String,
      default: 'green'
    },
    loading: {
      type: Boolean,
      default: false
    },
    xsColumn: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleCancel () {
      this.$emit('cancel')
      this.$emit('input', false)
    },
    handleConfirm () {
      this.$emit('confirm')
    },
    handleInput () {
      this.$emit('cancel')
      this.$emit('input', false)
    },
    parseMarkdown (text) {
      return marked(text, {
        headerIds: false,
        breaks: true
      })
    }
  },
  computed: {
    texts () {
      if (isArray(this.text) && this.text.length > 0) {
        return this.text.map(this.parseMarkdown)
      }

      if (isString(this.text)) {
        return [this.parseMarkdown(this.text)]
      }

      return null
    },
    cancelButtonText () {
      return this.cancel || this.$t('global.components.dialogs.buttons.cancel')
    },
    confirmButtonText () {
      return this.confirm || this.$t('global.components.dialogs.buttons.confirm')
    }
  }
}
</script>

<style scoped>
.v-card__text--markdown >>> h1,
.v-card__text--markdown >>> h2,
.v-card__text--markdown >>> h3,
.v-card__text--markdown >>> h4,
.v-card__text--markdown >>> h5,
.v-card__text--markdown >>> h6 {
  margin-bottom: 0.8em
}

>>> .v-card__title {
  word-break: break-word;
}
</style>
