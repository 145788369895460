import isArray from 'lodash/isArray'
import isString from 'lodash/isString'
import { addDays, startOfDay } from 'date-fns'

import config from '@/config/config'
import VueI18n from '@/i18n'

import { getDate, toLocaleDateString } from './datetime.helper'

function isSet (value) {
  if (isArray(value)) {
    return value.length > 0
  }

  return !!value
}

export const required = ({ field = null }, { data, labels }) => (value) => {
  if (field) {
    if (!isSet(data[field])) {
      return true
    }

    return isSet(value) || VueI18n?.t('global.form.errorMessages.fields.generic.requiredField', { field: labels[field] ?? field })
  }

  return isSet(value) || VueI18n?.t('global.form.errorMessages.fields.generic.required')
}

export const min = ({ min, number = false }) => (value) => {
  if (!value && (!number || value === 0 || value === '0')) {
    return true
  }

  if (number) {
    return (Number(value) >= min) || VueI18n?.t('global.form.errorMessages.fields.number.min', { number: min })
  }

  return (value.length >= min) || VueI18n?.t('global.form.errorMessages.fields.text.min', { length: min })
}

export const max = ({ max, number = false }) => (value) => {
  if (!value && (!number || value === 0 || value === '0')) {
    return true
  }

  if (number) {
    return (Number(value) <= max) || VueI18n?.t('global.form.errorMessages.fields.number.max', { number: max })
  }

  return (value.length <= max) || VueI18n?.t('global.form.errorMessages.fields.text.max', { length: max })
}

export const minDate = ({ min = null, allowSameDate = false, field = null }, { data, labels }) => (value) => {
  if (!value) {
    return true
  }

  let minValue = min

  if (!min) {
    minValue = data[field] ?? ''
  }

  const date = startOfDay(getDate(value))
  let minDate = startOfDay(getDate(minValue))

  if (!allowSameDate) {
    minDate = addDays(minDate, 1)
  }

  let errorMessage

  if (field) {
    errorMessage = VueI18n?.t('global.form.errorMessages.fields.date.minField', { field: labels[field] ?? field })
  } else {
    errorMessage = VueI18n?.t('global.form.errorMessages.fields.date.min', {
      date: toLocaleDateString(minDate, { year: 'numeric', month: '2-digit', day: '2-digit' })
    })
  }

  return date >= minDate ? true : errorMessage
}

export const maxDate = ({ max = null, allowSameDate = false, field = null }, { data, labels }) => (value) => {
  if (!value) {
    return true
  }

  let maxValue = max

  if (!max) {
    maxValue = data[field] ?? ''
  }

  const date = startOfDay(getDate(value))
  let maxDate = startOfDay(getDate(maxValue))

  if (!allowSameDate) {
    maxDate = addDays(maxDate, 1)
  }

  let errorMessage

  if (field) {
    errorMessage = VueI18n?.t('global.form.errorMessages.fields.date.maxField', { field: labels[field] ?? field })
  } else {
    errorMessage = VueI18n?.t('global.form.errorMessages.fields.date.max', {
      date: toLocaleDateString(maxDate, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      })
    })
  }

  return date >= maxDate ? true : errorMessage
}

export const numeric = () => (value) => !isNaN(value) || VueI18n?.t('global.form.errorMessages.fields.types.numeric')

export const uuid = () => (value) => {
  if (!value) {
    return true
  }

  // https://github.com/uuidjs/uuid/blob/master/src/regex.js
  const uuidRegEx = /^(?:[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}|00000000-0000-0000-0000-000000000000)$/i

  return (isString(value) && uuidRegEx.test(value)) || VueI18n?.t('global.form.errorMessages.fields.types.uuid')
}

export const email = () => (value) => {
  if (!value) {
    return true
  }

  // regex for RFC 5322 compliant email validation
  const emailRegEx = config.getRaw('tests.emailRegEx')

  return (isString(value) && emailRegEx.test(value)) || VueI18n?.t('global.form.errorMessages.fields.types.email')
}

export const noFileExt = () => (value) => {
  if (!value) {
    return true
  }

  return (isString(value) && !/\..*$/.test(value)) || VueI18n?.t('global.form.errorMessages.fields.text.noFileExt')
}

export const collection = ({ predicate, options = {} }, context) => (value) => {
  return (isArray(value) && value.every((item) => predicate(options, context)(item) === true)) || VueI18n?.t('global.form.errorMessages.fields.generic.collection')
}
