var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-text',[(_vm.isImage)?_c('img',{staticClass:"embedded-object mx-auto d-block",style:(_vm.cssVars),attrs:{"src":_vm.source,"alt":_vm.slotContent,"loading":"lazy"}}):_c('iframe',_vm._b({staticClass:"embedded-object",style:(_vm.cssVars),attrs:{"src":_vm.source,"loading":"lazy"}},'iframe',{ width: _vm.width, height: _vm.height },false),[_vm._t("default")],2)]),_c('v-card-actions',[_c('v-spacer'),_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":""},model:{value:(_vm.isFullscreen),callback:function ($$v) {_vm.isFullscreen=$$v},expression:"isFullscreen"}},[_c('v-card',{staticClass:"preview-card"},[_c('div',{staticClass:"preview-card__inner"},[_c('v-toolbar',{staticClass:"preview-card__header",attrs:{"color":"primary","dark":""}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","dark":""},on:{"click":_vm.exitFullscreen}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.$icons.mdiClose))])],1)]}}])},[_c('span',{domProps:{"textContent":_vm._s(_vm.closeButtonTooltip)}})]),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.heading))]),_c('v-spacer'),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.showDownload)?_c('v-btn',_vm._g(_vm._b({attrs:{"href":_vm.downloadLink,"icon":"","dark":"","target":"_blank","download":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.$icons.mdiDownload))])],1):_vm._e()]}}])},[_c('span',{domProps:{"textContent":_vm._s(_vm.downloadButtonTooltip)}})])],1),_c('div',{staticClass:"embedded-object embedded-object--fullscreen",style:(_vm.cssVarsFullscreen)},[(_vm.isImage)?_c('img',{staticClass:"embedded-object embedded-object--fullscreen flex-grow-1",style:(_vm.cssVars),attrs:{"src":_vm.source,"alt":_vm.slotContent,"loading":"lazy"}}):_c('iframe',{staticClass:"embedded-object embedded-object--fullscreen flex-grow-1",attrs:{"src":_vm.source}},[_vm._t("default")],2)])],1)])],1),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.isFullscreen = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.$icons.mdiFullscreen))])],1)]}}])},[_c('span',{domProps:{"textContent":_vm._s(_vm.previewButtonTooltip)}})]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.showDownload)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","href":_vm.downloadLink,"target":"_blank","download":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.$icons.mdiDownload))])],1):_vm._e()]}}])},[_c('span',{domProps:{"textContent":_vm._s(_vm.downloadButtonTooltip)}})])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }