var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('data-table',{attrs:{"table-name":"management.table","data-table":_vm.dataTable,"actions":_vm.actions,"event-handlers":_vm.eventHandlers,"search-action":_vm.searchAction,"min-search-term-length":3},scopedSlots:_vm._u([{key:"item.uuid",fn:function(ref){
var item = ref.item;
return [_c('copy',{attrs:{"text":item.uuid,"display":_vm.shortenUuid(item.uuid)}})]}},{key:"item.surrogatCustomerUid",fn:function(ref){
var item = ref.item;
return [_c('copy',{attrs:{"text":item.surrogatCustomerUid}})]}},{key:"item.createdOn",fn:function(ref){
var item = ref.item;
return [_c('formatted-date',{attrs:{"date":item.createdOn}})]}},{key:"item.documentFolderName",fn:function(ref){
var item = ref.item;
return [(!item.documentFolder.deletedOn)?_c('copy',{attrs:{"text":item.documentFolder.name}}):_c('span',{domProps:{"innerHTML":_vm._s(_vm.getDeletedFolderText(item.documentFolder.name))}})]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_c('copy',{attrs:{"text":((item.customer.givenName) + " " + (item.customer.familyName))}})]}},{key:"item.validity",fn:function(ref){
var item = ref.item;
return [(item.processStatus === _vm.requestStates.OPEN)?_c('span',[_vm._v(" "+_vm._s(_vm.toLocaleDateString(item.expiryDate, _vm.dateFormatConfig))+" "+_vm._s(_vm.timeString)+" ")]):_c('span',[_vm._v("–")])]}},{key:"item.processStatus",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.requestStates.getColor(item.processStatus),"text-color":"white","small":""}},[_vm._v(" "+_vm._s(_vm.requestStates.getTranslation(item.processStatus))+" ")])]}}])}),_c('confirmation-dialog',{attrs:{"title":_vm.resendDialogTitle,"text":_vm.resendDialogText,"cancel":_vm.resendDialogCancel,"confirm":_vm.resendDialogConfirm},on:{"cancel":_vm.resetResendDialog,"confirm":_vm.confirmResend},model:{value:(_vm.resendDialog),callback:function ($$v) {_vm.resendDialog=$$v},expression:"resendDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }