<template>
  <div>
    <data-table
      table-name="management.table"
      :data-table="dataTable"
      :actions="actions"
      :event-handlers="eventHandlers"
      :search-action="searchAction"
      :min-search-term-length="3"
    >
      <template #item.uuid="{ item }">
        <copy :text="item.uuid" :display="shortenUuid(item.uuid)"></copy>
      </template>

      <template #item.surrogatCustomerUid="{ item }">
        <copy :text="item.surrogatCustomerUid"></copy>
      </template>

      <template #item.createdOn="{ item }">
        <formatted-date :date="item.createdOn"></formatted-date>
      </template>

      <template #item.documentFolderName="{ item }">
        <copy
          v-if="!item.documentFolder.deletedOn"
          :text="item.documentFolder.name"
        ></copy>

        <span v-else v-html="getDeletedFolderText(item.documentFolder.name)"></span>
      </template>

      <template #item.customer="{ item }">
        <copy :text="`${item.customer.givenName} ${item.customer.familyName}`"></copy>
      </template>

      <template #item.validity="{ item }">
        <span v-if="item.processStatus === requestStates.OPEN">
          {{ toLocaleDateString(item.expiryDate, dateFormatConfig) }} {{ timeString }}
        </span>
        <span v-else>&ndash;</span>
      </template>

      <template #item.processStatus="{ item }">
        <v-chip
          :color="requestStates.getColor(item.processStatus)"
          text-color="white"
          small
        >
          {{ requestStates.getTranslation(item.processStatus) }}
        </v-chip>
      </template>
    </data-table>

    <confirmation-dialog
      v-model="resendDialog"
      :title="resendDialogTitle"
      :text="resendDialogText"
      :cancel="resendDialogCancel"
      :confirm="resendDialogConfirm"
      @cancel="resetResendDialog"
      @confirm="confirmResend"
    ></confirmation-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import { getDaysUntil, toLocaleDateString } from '@/helpers/datetime.helper'
import { shortenUuid } from '@/helpers/utility.helper'
import requestStates from '@/types/RequestStates'

import ConfirmationDialog from '../ConfirmationDialog'
import Copy from '../Copy'
import DataTable from '../DataTable'
import FormattedDate from '../FormattedDate'

import config from '@/config/config'

export default {
  name: 'RequestTable',
  components: {
    ConfirmationDialog,
    Copy,
    DataTable,
    FormattedDate
  },
  data () {
    return {
      getDaysUntil,
      requestStates,
      toLocaleDateString,
      primaryField: 'uuid',
      headers: [
        { value: 'uuid' },
        { value: 'customer' },
        { value: 'surrogatCustomerUid' },
        { value: 'createdOn' },
        { value: 'processStatus' },
        { value: 'documentFolderName' },
        { value: 'validity' },
        { value: 'actions', sortable: false, align: 'end' }
      ],
      eventHandlers: {
        'update:options': this.handleOptionsEvent
      },
      resendDialog: false,
      resendRequestItem: null
    }
  },
  async created () {
    await this.getDocumentFolders()
  },
  methods: {
    ...mapActions('requests', ['getRequests', 'updateOptions', 'resendRequest']),
    ...mapActions('documentFolders', ['getDocumentFolders']),
    ...mapActions('messageQueue', ['queueSuccess']),
    ...mapMutations('requests', ['ACTIVE_REQUEST', 'SEARCH_TERM']),
    shortenUuid,
    async handleOptionsEvent (event) {
      const { page = 1, itemsPerPage = 10 } = event

      this.updateOptions({ page, itemsPerPage })

      await this.getRequests()
    },
    setActiveRequestItem (item) {
      this.ACTIVE_REQUEST(item?.uuid ?? null)
    },
    searchHandler (searchTerm) {
      this.SEARCH_TERM(searchTerm)
    },
    showResendDialog (item) {
      this.resendRequestItem = item
      this.resendDialog = true
    },
    async confirmResend () {
      await this.resendRequest(this.resendRequestItem.uuid)
      this.resetResendDialog()
      this.queueSuccess(this.resendSuccessful)
    },
    resetResendDialog () {
      this.resendDialog = false
      this.resendRequestItem = null
    },
    getDeletedFolderText (folder) {
      return this.$tp('deletedFolder', { folder })
    }
  },
  computed: {
    ...mapGetters('requests', ['requests', 'total', 'loading', 'searchTerm', 'loadingResend', 'itemsPerPage', 'page', 'activeRequest']),
    dataTable () {
      return {
        headers: this.headers,
        items: this.requests,
        loading: this.loading,
        showSelect: false,
        itemKey: this.primaryField,
        disablePagination: false,
        serverItemsLength: this.total,
        hideDefaultFooter: false,
        search: this.searchTerm,
        disableSort: true,
        page: this.page,
        itemClass: (item) => {
          if (this.activeRequest && this.activeRequest.uuid === item.uuid) {
            return 'grey lighten-3'
          }

          return ''
        }
      }
    },
    actions () {
      return [
        {
          icon: this.$icons.mdiEmailSyncOutline,
          handler: this.showResendDialog,
          tooltip: this.resendTooltip,
          disabled: (item) => item.processStatus !== requestStates.OPEN || !item.customer.email
        },
        {
          icon: this.$icons.mdiChevronRight,
          handler: this.setActiveRequestItem,
          tooltip: this.detailViewTooltip
        }
      ]
    },
    searchAction () {
      return {
        label: this.$t(`${this.tableTranslationKey}.search.label`),
        hint: this.$t(`${this.tableTranslationKey}.search.hint`),
        handler: this.searchHandler
      }
    },
    tableTranslationKey () {
      return `${this.componentTranslationKey}.table`
    },
    documentFoldersAreLoaded () {
      return this.documentFolders.length > 0
    },
    detailViewTooltip () {
      return this.$t(`${this.tableTranslationKey}.actions.details.tooltip`)
    },
    resendTooltip () {
      return this.$t(`${this.tableTranslationKey}.actions.resend.tooltip`)
    },
    resendDialogTranslationKey () {
      return `${this.tableTranslationKey}.actions.resend.dialog`
    },
    resendDialogTitle () {
      return this.$t(`${this.resendDialogTranslationKey}.title`)
    },
    resendDialogText () {
      if (!this.resendRequestItem) {
        return ''
      }

      const name = `${this.resendRequestItem.customer.givenName} ${this.resendRequestItem.customer.familyName}`
      const email = this.resendRequestItem.customer.email

      return this.$t(`${this.resendDialogTranslationKey}.text`, { name, email })
    },
    resendDialogCancel () {
      return this.$t(`${this.resendDialogTranslationKey}.actions.cancel`)
    },
    resendDialogConfirm () {
      return this.$t(`${this.resendDialogTranslationKey}.actions.confirm`)
    },
    resendSuccessful () {
      return this.$t(`${this.resendDialogTranslationKey}.messages.success`)
    },
    timeString () {
      return this.$t('global.units.timeString')
    },
    dateFormatConfig () {
      return config.get('i18n.defaultLocalDateTimeStringOptions').config
    }
  },
  inject: [
    '$tp',
    'componentTranslationKey'
  ],
  watch: {
    searchTerm () {
      this.updateOptions({ page: 1, itemsPerPage: this.itemsPerPage })
      this.getRequests()
    }
  }
}
</script>
